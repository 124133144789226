/* eslint-disable @next/next/no-img-element */
import {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useContext
} from 'react'
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  Polyline
} from '@react-google-maps/api'
import { GlobalContext } from 'context/GlobalContext'

type LatLngLiteral = google.maps.LatLngLiteral
type DirectionsResult = google.maps.DirectionsResult
type MapOptions = google.maps.MapOptions

type Props = {
  directions?: DirectionsResult
  startPoint?: LatLngLiteral
  endPoint?: LatLngLiteral
  selectedRoute?: number
  setRoute: Function
  fetchDirections?: any
}

export default function Map({
  directions,
  startPoint,
  endPoint,
  selectedRoute = 0,
  setRoute,
  fetchDirections
}: Props) {
  const { setContext } = useContext(GlobalContext)

  const [location, setLocation] = useState<LatLngLiteral>({
    lat: 41.0370328,
    lng: 28.9675392
  })

  const mapRef = useRef<GoogleMap>()
  const onLoad = useCallback((map: any) => (mapRef.current = map), [])

  const options = useMemo<MapOptions>(
    () => ({
      mapId: 'eb58c8cb435cfc07',
      disableDefaultUI: true,
      zoomControl: true,
      draggable: true
    }),
    []
  )

  const handleonDragEnd = async (latLng: any, point: string) => {
    const newDirection = {
      lat: latLng.latLng.lat(),
      lng: latLng.latLng.lng()
    }
    //mapRef.current?.panTo(newDirection)
    fetchDirections(point, newDirection)
  }

  useEffect(() => {
    if (startPoint) {
      setLocation(startPoint)
      !endPoint && mapRef?.current?.panTo(startPoint)
    }
    if (endPoint) {
      setLocation(endPoint)
      !startPoint && mapRef?.current?.panTo(endPoint)
    }
    if (startPoint && endPoint) setContext('loading', 'Hesaplanıyor...')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPoint, endPoint])

  return (
    <GoogleMap
      zoom={14}
      center={location}
      mapContainerClassName="map-container"
      options={options}
      onLoad={onLoad}
      clickableIcons={false}
      //onUnmount={onUnmount}
      /* onMouseUp={(e) => console.log(e)}
      onClick={(e) => console.log(e)} */
    >
      {directions && (
        <>
          <DirectionsRenderer
            directions={directions}
            routeIndex={selectedRoute}
            // waypoints={[]}
            options={{
              //draggable: true,
              polylineOptions: {
                zIndex: 10,
                strokeColor: '#05913D',
                strokeWeight: 5,
                strokeOpacity: 0.8,
                visible: true
              },
              markerOptions: {
                //icon: 'media/marker-start.svg',
                // draggable: true
                visible: false
              }
            }}
          />
          {directions?.routes?.map((paths: any, index: number) => (
            <Polyline
              key={index}
              onClick={() => setRoute(index)}
              //onDragEnd={(latLng) => polylineonDragEnd(latLng)}
              path={paths?.overview_path}
              options={{
                strokeColor: '#111',
                strokeWeight: 3,
                zIndex: 1,
                strokeOpacity: 0.5,
                visible: selectedRoute == index ? false : true
              }}
            />
          ))}
        </>
      )}

      {startPoint && (
        <Marker
          draggable={true}
          position={startPoint}
          icon="/media/marker-start.svg"
          onDragEnd={(latLng) => handleonDragEnd(latLng, 'start_point')}
        />
      )}

      {endPoint && (
        <Marker
          draggable={true}
          position={endPoint}
          icon="/media/marker-end.svg"
          onDragEnd={(latLng) => handleonDragEnd(latLng, 'end_point')}
        />
      )}
    </GoogleMap>
  )
}
