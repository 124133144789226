import React, { useState, useEffect, useRef, useContext } from 'react'
import cookie from 'js-cookie'
import { getMapAddress } from 'helpers/filterHelper'
import { GlobalContext } from 'context/GlobalContext'

type PlacesProps = {
  className: string
  placeholder: string
  setPoint?: (position: google.maps.LatLngLiteral) => void
}

export default function Places({
  className,
  placeholder,
  setPoint
}: PlacesProps) {
  let autoComplete: any

  const { setService, service, setContext } = useContext(GlobalContext)
  const [query, setQuery] = useState('')
  //const [address, setAddress] = useState('')
  const autoCompleteRef = useRef(null)

  useEffect(() => {
    if (className == 'start_point') {
      setQuery(service?.start_point?.address)
      //setAddress(service?.start_point?.address)
    } else if (className == 'end_point') {
      setQuery(service?.end_point?.address)
      //setAddress(service?.end_point?.address)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service?.end_point?.address, service?.start_point?.address])

  async function handlePlaceSelect() {
    const result = autoComplete.getPlace()

    if (!result?.address_components) {
      setContext({
        modalData: {
          name: 'global',
          type: 'alert',
          title: 'Seçim Hatası',
          text: 'Çıkan sonuçlardan seçim yapın!'
        }
      })
      return
    }
    setQuery(result?.name)

    // Başlangıç Şehri ayarlama
    const address = result?.address_components
    const { country, city, district } = getMapAddress(address)

    if (className == 'start_point' && city) {
      cookie.set('city', city)
      setService({
        city: city,
        start_point: {
          type: result?.types[0],
          name: result?.name,
          address: result?.formatted_address,
          district: district,
          city: city,
          country: country,
          place_id: result?.place_id
        }
      })
    }

    if (className == 'end_point') {
      setService({
        end_point: {
          type: result?.types[0],
          name: result?.name,
          address: result?.formatted_address,
          district: district,
          city: city,
          country: country,
          place_id: result?.place_id
        }
      })
    }

    const lat = result?.geometry?.location?.lat()
    const lng = result?.geometry?.location?.lng()
    if (lat && lng && setPoint) setPoint({ lat, lng })
  }

  function handleAutoComplete(autoCompleteRef: any) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        componentRestrictions: { country: 'tr' }
      }
    )
    autoComplete.setFields([
      'address_components',
      'formatted_address',
      'name',
      'geometry',
      'place_id',
      'types'
    ])

    autoComplete.addListener('place_changed', () => {
      handlePlaceSelect()
    })
  }

  useEffect(() => {
    handleAutoComplete(autoCompleteRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="search-location-input">
      <input
        ref={autoCompleteRef}
        className={className}
        placeholder={placeholder}
        value={query}
        onChange={(event) => setQuery(event.target.value)}
      />
      <button className="btn_delete" onClick={() => setQuery('')}></button>
    </div>
  )
}
