import Head from 'next/head'
import React, { FC, useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'context/GlobalContext'
import { useRouter } from 'next/router'

interface Props {
  title?: string
  description?: string
  image?: string
  hreflangs?: any
  cononical?: string
}
const SeoHead: FC<Props> = ({
  title,
  description,
  image,
  hreflangs,
  cononical
}: Props) => {
  const { site, baseImageURL } = useContext(GlobalContext)
  const router = useRouter()
  const [origin, setOrigin] = useState('')

  const seo_title =
    title == site?.seo_title ? site?.seo_title : title + ' - ' + site?.seo_title
  const seo_description = description ? description : site?.seo_description
  const seo_url = origin + router.pathname

  const base_img_path = baseImageURL + '1200/630/fill/'
  const seo_image = image ? image : site?.default_img

  useEffect(() => {
    var origin = window.location.origin
    setOrigin(origin)
  }, [])

  return (
    <Head>
      <title>{seo_title}</title>
      <meta name="description" content={seo_description} />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1"
      />
      {hreflangs &&
        hreflangs?.map((hreflang: any, index: number) => (
          <link
            key={index}
            rel="alternate"
            hrefLang={hreflang?.lang}
            href={
              hreflang?.lang != router.defaultLocale
                ? origin + '/' + hreflang?.lang + '/' + (hreflang?.url || '')
                : origin + '/' + (hreflang?.url || '')
            }
          />
        ))}

      {cononical && <link rel="canonical" href={cononical} />}

      {/*<!-- Google / Search Engine Tags -->*/}
      <meta itemProp="name" content={seo_title} />
      <meta itemProp="description" content={seo_description} />
      <meta itemProp="image" content={base_img_path + seo_image} />

      {/*<!-- Facebook Meta Tags -->*/}
      <meta property="og:title" content={seo_title} />
      <meta property="og:description" content={seo_description} />
      <meta property="og:image" content={base_img_path + seo_image} />
      <meta property="og:url" content={seo_url} />
      <meta property="og:type" content="website" />

      {/*<!-- Twitter Meta Tags -->*/}
      <meta name="twitter:title" content={seo_title} />
      <meta name="twitter:description" content={seo_description} />
      <meta name="twitter:image" content={base_img_path + seo_image} />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  )
}

export default SeoHead
