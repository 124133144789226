import Script from 'next/script'
export default function ArticleAd() {
  return (
    <>
      <Script
        id="Adsense-id"
        async
        onError={(e) => {
          console.error('Script failed to load', e)
        }}
        strategy="afterInteractive"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6568049615526713"
        crossOrigin="anonymous"
      />

      <div>
        <ins
          className="adsbygoogle"
          style={{ display: 'block', textAlign: 'center' }}
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client="ca-pub-6568049615526713"
          data-ad-slot="3671188839"
        ></ins>

        <Script
          id="google-gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(adsbygoogle = window.adsbygoogle || []).push({});`
          }}
        />
      </div>
    </>
  )
}
