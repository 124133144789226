/* eslint-disable @next/next/no-img-element */
import { FC, useContext, useEffect, useState } from 'react'
import { getIndex } from 'helpers/filterHelper'
import { axiosGet } from 'helpers/apiHelpers'
import { css } from '@emotion/react'
import Img from '../global/Img'
import { currency } from 'helpers/filterHelper'
import { GlobalContext } from 'context/GlobalContext'

type Props = {
  route: number
  leg: google.maps.DirectionsLeg
}

const TollRoadList: FC<Props> = ({ route = 1, leg }: Props) => {
  const {
    _local,
    service,
    fuel,
    setService,
    vehicle_class,
    baseMediaURL,
    setContext
  } = useContext(GlobalContext)

  const [noway, setWay] = useState(false)
  const [toll_road_list, setTollRoadList] = useState(Array)
  const [toll_roads, setTollRoads] = useState(Array)
  const [fuel_price, setFuelPrice] = useState(0)

  useEffect(() => {
    axiosGet('toll_roads').then((res) => setTollRoadList(res))
  }, [])

  useEffect(() => {
    if (leg) {
      //const tolls: Array<Object> = []
      const toll_roads_distance: Array<Object> = []

      leg.steps.map(async (step: any) => {
        if (
          step.instructions.includes('Paralı yol') ||
          step.instructions.includes('Feribot')
        ) {
          toll_roads_distance.push({
            km: parseFloat((step?.distance?.value / 1000).toFixed(1)),
            road: step?.instructions?.replace(/<[^>]*>?/gm, ''),
            lat: step.start_location.lat(),
            lng: step.start_location.lng()
          })
          //tolls.push(step?.instructions?.replace(/<[^>]*>?/gm, ''))
        }
      })

      setService({
        duration: leg.duration,
        distance: leg.distance,
        toll_roads_distance: toll_roads_distance,
        route: route + 1,
        toll_roads: [],
        toll_price_total: 0
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leg])

  useEffect(() => {
    setWay(false)
    const tolls: Array<Object> = []
    var distance = 0
    var first = false
    var next = false
    var first_coordinate = false
    var next_coordinate = false
    var road_exist = false

    if (service?.toll_roads_distance?.length > 0) {
      service?.toll_roads_distance?.map((toll: any) => {
        toll_road_list?.length > 0 &&
          toll_road_list?.map((road: any) => {
            // Yol sırasına göre bulma
            road?.in_orders?.filter((in_order: any) => {
              if (toll.road.includes(in_order?.first)) {
                first = true
              }

              if (first) {
                if (toll.road.includes(in_order?.next)) {
                  next = true
                }
              }
              var tollsIndex = getIndex(tolls, 'title', road.title)

              if (first && next && tollsIndex == -1) {
                var road_price = road.prices[vehicle_class].price
                if (!road_price) setWay(true)
                tolls.push({
                  icon: road.icon,
                  title: road.title,
                  price: road_price,
                  distance: distance?.toFixed(1),
                  highway: road.highway,
                  type: road.type
                })
              }
            })

            // Koordinata sırasına göre bulma
            road?.coordinates?.filter((coordinate: any) => {
              if (toll?.lng < coordinate?.first) {
                first_coordinate = true
              }
              if (first_coordinate) {
                if (toll?.lng > coordinate?.next) {
                  next_coordinate = true
                }
              }
              if (toll.road.includes(coordinate?.road)) {
                road_exist = true
              }

              var tollsIndex = getIndex(tolls, 'title', road.title)

              if (getIndex(tolls, 'title', 'F.S.M. Köprüsü') > -1) {
                tollsIndex = 0
              }
              if (getIndex(tolls, 'title', '15 Temmuz Şehitler Köprüsü') > -1) {
                tollsIndex = 0
              }

              if (
                first_coordinate &&
                next_coordinate &&
                road_exist &&
                tollsIndex == -1
              ) {
                var road_price = road.prices[vehicle_class].price
                if (!road_price) setWay(true)
                tolls.push({
                  icon: road.icon,
                  title: road.title,
                  price: road_price,
                  distance: distance?.toFixed(1),
                  highway: road.highway,
                  type: road.type
                })
              }
            })

            road.google_road_names.map((road_name: string) => {
              if (toll.road.includes(road_name)) {
                var price = 0
                var tollsIndex = getIndex(tolls, 'title', road.title)

                if (tollsIndex == -1) {
                  distance = toll?.km
                  var road_price = road.prices[vehicle_class].price
                  if (!road_price) setWay(true)
                  price =
                    road?.type == 'highway'
                      ? (road_price * distance).toFixed(0)
                      : road_price

                  tolls.push({
                    icon: road.icon,
                    title: road.title,
                    price: price,
                    distance: distance.toFixed(1),
                    highway: road.highway,
                    type: road.type
                  })
                } else {
                  distance = distance + toll?.km
                  var road_price = road.prices[vehicle_class].price

                  price =
                    road?.type == 'highway'
                      ? (road_price * distance).toFixed(0)
                      : road_price

                  tolls[tollsIndex] = {
                    icon: road.icon,
                    title: road.title,
                    price: price,
                    distance: distance.toFixed(1),
                    highway: road.highway,
                    type: road.type
                  }
                }
              }
            })
          })
        first = false
        next = false
      })
    }
    first_coordinate = false
    next_coordinate = false
    road_exist = false
    setTollRoads(tolls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service.toll_roads_distance, toll_road_list, vehicle_class])

  const setServiceTollRoads = async (
    toll_roads: any,
    toll_price_total: number
  ) => {
    await setService({ toll_roads, toll_price_total })
    setContext('loading', false)
  }

  useEffect(() => {
    // total toll road price
    var toll_price_total = 0
    if (toll_roads.length > 0) {
      toll_roads?.map(async (toll: any) => {
        toll_price_total = toll_price_total + parseFloat(toll.price)
      })
    }
    if (toll_roads && toll_price_total > 0) {
      setServiceTollRoads(toll_roads, toll_price_total)
    }
    setContext('loading', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toll_roads])

  useEffect(() => {
    if (fuel?.fuel_average && service?.distance?.value) {
      var km = service?.distance?.value / 1000 / 100
      var fuel_price = fuel.fuel_average * fuel.fuel_price
      fuel_price = fuel_price * km

      setFuelPrice(fuel_price)
    } else {
      setFuelPrice(0)
    }
    setContext('loading', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuel, service])

  if (!toll_roads?.length && !fuel_price) {
    return <></>
  }
  return (
    <div css={styles.container}>
      {noway ? (
        <div className="red">
          Rıtada bu araç sınıf için geçişi olmayan yol var!
        </div>
      ) : (
        <>
          <div className="alert_danger m-bottom-10">
            Ücretler bilgilendirme amaçlıdır. Gişelerde farklılıklar olabilir.
          </div>

          <div className="ekstra_title">
            {_local('toplam')}
            <span className="f-right">
              {currency(service?.toll_price_total + fuel_price)}
            </span>
          </div>

          {fuel_price > 0 ? (
            <>
              <div className="toll_item">
                <img className="icon" src={'/media/icon-fuel.svg'} alt="icon" />
                <div className="description">
                  {_local('Yakıt Tüketimi')}{' '}
                  {fuel_price &&
                    '(' + fuel?.fuel_average + 'L, ' + fuel?.fuel_price + '₺)'}
                </div>
                <div className="price">{currency(fuel_price)}</div>
              </div>
            </>
          ) : (
            <small className="toll_item danger">
              Yakıt tüketimi hesaplamak için yakıt litre ortalama ve fiyatı
              girin.
            </small>
          )}

          {service?.toll_roads?.map((toll: any, index: number) => (
            <div key={index} className="toll_item">
              <Img
                className="icon"
                path={baseMediaURL}
                src={toll?.icon ? toll?.icon : 'icon-otoyol.svg'}
                alt={toll?.title}
              />
              <div className="description">
                {toll?.title}
                {toll?.highway == 1 && ' (' + toll?.distance + ' km)'}
              </div>
              <div className="price">{currency(toll?.price)}</div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default TollRoadList

const styles = {
  container: css`
    width: 100%;
    clear: both;
    margin-bottom: 10px;
    border-radius: 4px;
    text-align: left;
    .ekstra_title {
      font-size: 16px;
      font-weight: 700;
      color: var(--color);
      padding-bottom: 2px;
      margin-bottom: 5px;
      border-bottom: 1px solid var(--borderColor);
      text-align: left;
    }
    .toll_item {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 5px;
      .icon {
        width: 20px;
        height: 20px;
        flex-basis: content;
      }
      .description {
        font-size: 14px;
        font-weight: 400;
      }
      .price {
        font-size: 14px !important;
        margin-left: auto;
        font-weight: 700;
      }
      @media only screen and (max-width: 480px) {
        .description {
          font-size: 12px;
        }
        .price {
          font-size: 12px !important;
        }
      }
    }
  `
}
