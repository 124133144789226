import React, { FC, useContext } from 'react'
import { css } from '@emotion/react'
import { useForm } from 'react-hook-form'
import { GlobalContext } from 'context/GlobalContext'
import { axiosPost } from 'helpers/apiHelpers'
import FormError from 'components/global/FormError'

type Option = {
  full_name: string
  eposta: string
  message: string
}
const FormContact: FC = () => {
  const { setContext, _local } = useContext(GlobalContext)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<Option>({ mode: 'onChange' })

  const onSubmit = async (data: any) => {
    setContext('loading', _local('gonderiliyor'))
    const res = await axiosPost('sendmail', {
      subject: 'İletişim Formu Mesajı',
      body: data.message + ' ' + data.full_name + '<br/>' + data.eposta
    })
    if (res == 'OK') {
      reset()
      setContext('loading', '')
      setContext('modalData', {
        name: 'global',
        type: 'success',
        text: _local('basariyla_gonderildi')
      })
    } else {
      setContext('loading', '')
      setContext('modalData', {
        name: 'global',
        type: 'alert',
        title: 'Hata',
        text: _local('gonderilemedi_tekrar_deneyin')
      })
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      css={styles.contactForm}
      acceptCharset="UTF-8"
    >
      <div className="flex gap-10">
        <div className="form_group">
          <label>{_local('ad_soyad')}</label>
          <input
            {...register('full_name', {
              required: _local('ad_soyad_yazin')
            })}
          />
          <FormError error={errors?.full_name?.message} />
        </div>

        <div className="form_group">
          <label>{_local('eposta')}</label>
          <input
            {...register('eposta', {
              required: _local('epostanizi_yazin'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: _local('epostanizi_kontrol_edin')
              }
            })}
          />
          <FormError error={errors?.eposta?.message} />
        </div>
      </div>
      <div className="form_group">
        <label>{_local('hata_yazin')}</label>
        <textarea
          rows={3}
          {...register('message', {
            required: _local('mesaj_yazin')
          })}
          placeholder='Yol ücretlerinde gördüğünüz hataları yazabilirsiniz.'
        />
        <FormError error={errors?.message?.message} />
      </div>

      <button type="submit" className="btn max alert">
        {_local('hata_bildir')}
      </button>
    </form>
  )
}

export default FormContact

const styles = {
  contactForm: css`
    .form_group {
      textarea {
        padding: 10px;
      }
    }
  `
}
