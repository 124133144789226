/* eslint-disable @next/next/no-img-element */
import { FC, useEffect, useState } from 'react'

interface Props {
  className?: string
  path?: string
  src: string
  width?: number
  height?: number
  crop?: string
  alt: string
}
const Img: FC<Props> = ({
  className = '',
  path,
  src,
  width = 0,
  height = 0,
  crop = '',
  alt
}) => {
  let [state_src, setSrc] = useState('')
  let [src_set_m, setSrcsetM] = useState('')
  let [src_set_s, setSrcsetS] = useState('')
  let [src2x, setSrc2x] = useState('')
  let [data_src, setDataSrc] = useState('')

  var isSvg = typeof src === 'string' && src.search('.svg') < 0 ? false : true
  var img_width = width ? width : 0
  var img_height = height ? '/' + height : '/' + 0
  var isCrop = crop ? '/' + crop + '/' : '/'

  const set2x = (src2x: string) => {
    return src2x.replace(/(\.[^\.]+)$/, '@2x$1') + ' 2x'
  }

  useEffect(() => {
    const setHeight = (w: number) => {
      return height > 0 ? Math.round((w * height) / width) : height
    }
    params =
      (!img_width && !img_height) || isSvg
        ? ''
        : img_width + img_height + isCrop
    setDataSrc(path + params + src)
    setSrc(path + params + src)
    !isSvg && setSrc2x(set2x(path + params + src))
    if (width >= 540 && !isSvg) {
      // Small Responsive ayarları
      var new_height = setHeight(480)
      var params = '480/' + new_height + isCrop
      var Src = path + params + src
      var Src2x = set2x(Src)
      setSrcsetS(Src + ' 1x,' + Src2x)
    }

    if (width >= 900 && !isSvg) {
      // Medium Responsive ayarları
      var new_height = setHeight(800)
      var params = '800/' + new_height + isCrop
      var Src = path + params + src
      var Src2x = set2x(Src)
      setSrcsetM(Src + ' 1x,' + Src2x)
    }
  }, [src, isCrop, img_height, img_width, isSvg, path, width, height])

  return (
    <picture>
      {src_set_s && <source media="(max-width:480px)" srcSet={src_set_s} />}
      {src_set_m && <source media="(max-width:800px)" srcSet={src_set_m} />}
      <img
        src={state_src}
        srcSet={isSvg ? data_src : src2x}
        data-src={data_src}
        className={className}
        width={width > 0 ? width : 'auto'}
        height={height > 0 ? height : 'auto'}
        alt={alt || src}
        loading="lazy"
      />
    </picture>
  )
}

export default Img
